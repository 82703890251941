<template>
  <travio-center-container grid-width="1/2" :pageTitle="pageTitle">
    <vx-card>
      <div v-if="!readOnly" class="flex flex-wrap items-center mb-base">
        <vs-avatar 
          :src="form.profilePicUrl"
          icon="cloud_upload"
          @click="$refs.profilePicInput.click()"
          size="70px" :disabled="readOnly"
          class="mr-4 mb-4 company-logo-avatar" />
        <div>
          <input type="file" hidden id="profilePicInput" ref="profilePicInput" v-on:change="handleFileUpload"/>
          <vs-button @click="$refs.profilePicInput.click()" class="mr-4 sm:mb-0 mb-2">Upload Profile Picture</vs-button>
          <vs-button @click="removeLogo" type="border" color="danger">Remove</vs-button>
          <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
        </div>
      </div>

      <div v-if="readOnly" class="flex flex-wrap items-center mb-base">
        <div class="img-container" style="width:70px">
          <img :src="form.profilePicUrl" class="rounded w-full" />
        </div>
      </div>

      <div class="vx-row">
        <!-- General Info Col -->
        <div class="vx-col w-full md:w-1/2">

          <!-- Col Header -->
          <div class="flex items-end">
            <feather-icon icon="SettingsIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">General</span>
          </div>

          <!-- Col Content -->
          <div>
            <vs-input v-if="$route.params.userId" class="w-full mt-3" label="ID" :value="$route.params.userId" name="Id" disabled />
            
            <vs-input class="w-full mt-3" label="Email*" v-model="form.email" v-validate="'required|email'" name="Email" :disabled="readOnly || editMode" />
            <span class="text-danger text-sm">{{ errors.first('Email') }}</span>

            <div class="mt-3">
              <label class="text-sm">Title</label>
              <v-select :value="selectedTitle" @input="setSelectedTitle" :options="titleOptions" :disabled="readOnly" />
            </div>
            
            <vs-input class="w-full mt-3" label="First Name" v-model="form.firstName" v-validate="'max:50'" name="First Name" :disabled="readOnly" />
            <span class="text-danger text-sm">{{ errors.first('First Name') }}</span>

            <vs-input class="w-full mt-3" label="Last Name" v-model="form.lastName" v-validate="'max:50'" name="Last Name" :disabled="readOnly" />
            <span class="text-danger text-sm">{{ errors.first('Last Name') }}</span>
            

            <!-- Roles -->
            <div class="mt-3">
              <label class="text-sm">Security Role*</label>
              <!-- <ValidationProvider rules="required" ref="provider" v-slot="{ errors }"> -->
                <v-select v-model="selectedRole" @input="setSelectedRole" v-validate="'required'" name="Security Role" :options="roleOptions" :disabled="readOnly" />
                <span class="text-danger text-sm">{{ errors.first('Security Role') }}</span>
                <!-- <span class="text-danger text-sm">{{ errors[0] ? 'Security Role is required' : ''  }}</span> -->
              <!-- </ValidationProvider> -->
            </div>
          </div>

          <vs-input class="w-full mt-3" label="Commision %" v-model.number="form.commisionPercentage" type="number" step="0.1" name="Commision Percentage" @input="setEmptyToNull" :disabled="readOnly"
            v-validate="{
              rules: {
                required: false,
                min_value: 1,
                max_value: 100,
                integer: false
              },
            }"
          />
          <span class="text-danger text-sm">{{ errors.first('Commision Percentage') }}</span>
        </div>

        <!-- Personal Info Col -->
        <div class="vx-col w-full md:w-1/2">

          <!-- Col Header -->
          <div class="flex items-end md:mt-0 mt-base">
            <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Personal Info</span>
          </div>

          <!-- Col Content -->
          <div>
            <vs-input class="w-full mt-3 mb-3" label="Job Position/Title" v-model="form.roleTitle" v-validate="'max:50'" name="Job Position/Title" :disabled="readOnly" />  
            <span class="text-danger text-sm">{{ errors.first('Job Position/Title') }}</span>

            <!-- DOB -->
            <div class="mt-3">
              <label class="text-sm">Date of Birth</label>
              <flat-pickr v-model="form.dob" :config="{ dateFormat: 'Y-m-d' }" class="w-full" name="dob" :disabled="readOnly" />
              <!-- <span class="text-danger text-sm"  v-show="errors.has('dob')">{{ errors.first('dob') }}</span> -->
            </div>
            
            <!-- Country -->
            <div class="mt-3">
              <label class="text-sm">Country</label>
              <v-select :value="selectedCountry" @input="setSelectedCountry" :options="countryOptions" :disabled="readOnly" />
            </div>

            <!-- Languages -->
            <div class="mt-3">
              <label class="text-sm">Spoken Languages</label>
              <v-select :value="selectedLanguages" @input="setSelectedLanguages" multiple :closeOnSelect="false" :options="langOptions" :disabled="readOnly" />
            </div>
            <vs-input class="w-full mt-3" label="Phone" v-model="form.phone" type="number" name="Phone" :disabled="readOnly" v-validate="'max:20'" />
            <span class="text-danger text-sm"  v-show="errors.has('Phone')">{{ errors.first('Phone') }}</span>
            
            <vs-input class="w-full mt-3" label="Backoffice Reference" v-model="form.backOfficeReference" v-validate="'max:50'" name="Backoffice Reference" :disabled="readOnly" />
            <span class="text-danger text-sm">{{ errors.first('Backoffice Reference') }}</span>

          </div>
        </div>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button v-if="!readOnly"  @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button v-if="userHasPermission('company_users_edit') && !readOnly" @click="handleSubmit" class="mt-4">Save</vs-button>
        <vs-button v-if="userHasPermission('company_users_edit') && readOnly" @click="handleEditMode" class="mt-4 mr-4">Edit</vs-button>
        <vs-button v-if="readOnly" @click="handleCancel" class="mt-4">Back</vs-button>
        
      </div>
    </vx-card>
  </travio-center-container>
</template>

<script>
import userStore from './userStore.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Form } from '@/core-modules/form-framework/Form.js'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    flatPickr
  },
  mixins: [TpNotificationMixin],
  props: {
    readOnly: { type: Boolean, default: true } 
  },
  data () {
    return {
      form: new Form({
        email: '',
        firstName: null,
        lastName: '',
        dob: null,
        phone: '',
        countryCode: null,
        spokenLanguages: null,
        isActive: true,
        roleTitle: '',
        roleId: 0,
        title: 0,
        userKey: null,
        profilePicUrl: null,
        commisionPercentage: null,
        backOfficeReference: '',
      }),
      selectedRole: null,
      selectedCountry: null,
      selectedLanguages: null,
      selectedTitle: null,
      profilePic: null
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    editMode () {
      return !this.readOnly && this.$route.params.userId
    },
    createMode () {
      return !this.readOnly && !this.$route.params.userId
    },
    roleOptions () {
      return this.$store.state.userStore.companyRoleOptions
    },
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    langOptions () {
      return this.$store.state.lookups.languageOptions
    },
    pageTitle () {
      if (this.readOnly) return 'View User'
      return this.editMode ? 'Edit User' : 'Add User'
    }
  },
  created () {
    if (!userStore.isRegistered) {
      this.$store.registerModule('userStore', userStore)
      userStore.isRegistered = true

      // If user hasn't gone to the user list yet we need to fetch user list
      this.$store.dispatch('userStore/fetchUsers', this.$route.params.companyId )
        .catch(err => console.error(err) )
    }
  },
  mounted () {

    if (this.$route.params.userId > 0) {
      this.$vs.loading()
      this.$http.get(`api/companies/${this.$route.params.companyId}/users/${this.$route.params.userId}`)
        .then(response => {
          Object.assign(this.form, response.data)

          this.selectedTitle = this.titleOptions.find(x => x.code == response.data.title) || {};
          this.selectedCountry = this.countryOptions.find(x => x.code == response.data.countryCode) || {};

          const spokenLanguages = response.data.spokenLanguages ? response.data.spokenLanguages.split(',') : null;
          this.selectedLanguages = this.langOptions.filter(x => {
            return spokenLanguages && spokenLanguages.includes(x.code)
          });

          this.$store.dispatch('userStore/fetchRoleOptions', this.$route.params.companyId )
            .then(resp => {
              this.selectedRole = this.roleOptions.find(x => x.code == response.data.roleId) || {};
            })
            .catch(err => console.error(err) )
        })
        .catch(error => {
          this.$_notifyFailureByResponseData(error.response.data)
          this.$router.push(`/companies/${this.$route.params.companyId}/users`)
        })
        .finally(() => this.$vs.loading.close())
    } else {
      this.$store.dispatch('userStore/fetchRoleOptions', this.$route.params.companyId )
        .catch(err => console.error(err) )
    }

    
  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    setSelectedRole (value) {
      this.form.roleId = value ? value.code : null
    },
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    setSelectedLanguages (value) {
      this.selectedLanguages = value;
      this.form.spokenLanguages = this.selectedLanguages.length 
        ? this.selectedLanguages.map((item) => item.code).join(',')
        : null
    },
    setSelectedTitle (value) {
      this.form.title = value ? value.code : 0
      this.selectedTitle = value;
    },
    handleFileUpload () {
      this.profilePic = this.$refs.profilePicInput.files[0];
      if (this.profilePic && this.createMode) {
        // Temporary render file, can't upload yet since Application ID is not yet available
        this.form.profilePicUrl = URL.createObjectURL(this.profilePic)
      }
      else if (this.profilePic && this.editMode) {
        this.$vs.loading()
        this.submitProfilePic(this.$route.params.userId)
        .then(response => this.$_notifySuccess('Successfully uploaded profile picture'))
        .catch(error => this.$_notifyFailure('Unable to upload profile picture, you may go to application settings and try again.'))
        .finally(() => {this.$vs.loading.close()})
      }
    },

    handleSubmit () {
      this.$validator.validateAll().then(result => {
        
        if (result) {
          if (this.editMode) {
            this.handleSubmitEdit()
          } else {
            this.handleSubmitAdd()
          }
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleSubmitAdd () {
      this.$vs.loading();
      const userId = parseInt(this.$route.params.userId)
      
      this.$store.dispatch('userStore/addUser', { companyId: this.$route.params.companyId, data: this.form.data() })
      .then(response => {

        if (this.profilePic) {
          this.submitProfilePic(response.data.userId, true)
          .then(response => this.$_notifySuccess('Successfully added new user'))
          .catch(error => this.$_notifyFailure('Successfully added new user but failed to upload profile picture, you may go to application settings and try again.'))
          .finally(() => {
            this.$router.push(`/companies/${this.$route.params.companyId}/users`)  
            this.$vs.loading.close()
          })
        } else {
          this.$router.push(`/companies/${this.$route.params.companyId}/users`)
        }
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    handleSubmitEdit () {
      this.$vs.loading();
      const userId = parseInt(this.$route.params.userId)
      
      this.$store.dispatch('userStore/updateUser', { companyId: this.$route.params.companyId, data: this.form.data(), userId: userId })
      .then(response => {

        if (this.profilePic) {
          this.submitProfilePic(userId)
          .then(response => this.$_notifySuccess('Successfully updated user'))
          .catch(error => this.$_notifyFailure('Successfully added updated user but failed to upload logo, you may go to application settings and try again.'))
          .finally(() => {
            this.$router.push(`/companies/${this.$route.params.companyId}/users`)  
            this.$vs.loading.close()
          })
        } else {
          this.$router.push(`/companies/${this.$route.params.companyId}/users`)
        }
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    submitProfilePic (userId) {
      let formData = new FormData();
      formData.append('profilePic', this.profilePic)
      return new Promise((resolve, reject) => {
        this.$http.post(`api/companies/${this.$route.params.companyId}/users/${userId}/profilePictures`, formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
        })
        .then(response => {
          this.form.profilePicUrl = response.data.profilePicUrl
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    handleReset () {
      this.selectedRole = {}
      this.selectedCountry = {}
      this.selectedLanguages = null
      this.selectedTitle = {}
      this.form = new Form(this.form.originalData)
    },
    handleCancel () {
      this.$router.push(`/companies/${this.$route.params.companyId}/users`)
    },
    removeLogo () {
      this.$refs.profilePicInput.value = '';
      this.form.profilePicUrl = '';
    },
    setEmptyToNull (val) {
      if (val === '') {
        this.form.commisionPercentage = null
      }
    },
    handleEditMode () {
      this.$router.push(`/companies/${this.$route.params.companyId}/users/${this.$route.params.userId}/edit`)
        .catch((error) => { console.error(error) })
  }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }

  #avatar-col {
    width: 10rem;
  }
</style>
